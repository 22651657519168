'use client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { ENUM_USER_STATUS, IUniversity, StatusTypeEnum } from './types';
import { set } from 'lodash';

export const useClinicSlice = createSlice({
  name: 'universityState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setEditLoading: (state, action: PayloadAction<boolean>) => {
      state.editLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    toggleAddNew: (state, action: PayloadAction<boolean>) => {
      state.addNew = action.payload;
    },

    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form._id = '';
      state.form.title = '';
      state.form.email = '';
      state.form.description = '';
      state.form.address = '';
      state.form.createdAt = '';
      state.form.updatedAt = '';
      state.form.seoTitle = '';
      state.form.user = '';
      state.form.isActive = ENUM_USER_STATUS.ACTIVE;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetAmountHistoryList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},

    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doResendEmail: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {},
    setList: (state, action: PayloadAction<Array<IUniversity>>) => {
      state.list = action.payload;
    },

    setDataForEdit: (state, action: PayloadAction<IUniversity>) => {
      state.form = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
